import React, {useEffect, useState} from 'react';
import { HexClient as client } from './client';
import Chat from "./chat";
import DeviceSelect from "./deviceSelect";
import {
  Grid,
  Container,
  Alert,
  Box
} from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import Api from "./api";
import * as ld from 'lodash';

function Playground() {
  const [corpAccess, setCorpAccess] = useState(null);
  const [activeDevice, setActiveDevice] = useState(null);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
      // Cleanup function
      return () => {
          if (activeDevice) {
            activeDevice.endstream();
          }
      };
  }, []);

  useEffect(() => {
    let ping = async () => {
      try {
        if (corpAccess === null) {
          let resp = client.ping();
          setCorpAccess(ld.get(await resp, 'status', '') === 200);
        }
      } catch (err) {
        setCorpAccess(false);
        setErrors([...errors, 'Cannot verify corp connectivity to OAK']);
      }
    }
    ping();
  });

  return (
    !corpAccess ? (
        <Alert type="warning">Access to Playground requires Corp access and CID allowlisted in OAK</Alert>
    ) : (
          <div>
            { activeDevice ? (
              <Container
                disableContentPaddings
              >
                  { activeDevice.inOak ? (
                      <Grid disableGutters gridDefinition={[ {colspan: 8}, {colspan: 4} ]}>
                        <Chat device={activeDevice}/>
                        <Api device={activeDevice}/>
                      </Grid>
                    ) : (
                      <Box margin="xxl" padding="xxl" fontSize="heading-m">
                        Connect the device to OAK by clicking on the Connect button
                      </Box>
                    )
                  }
              </Container>
            ) : (
              <Grid
                gridDefinition={[{ colspan: 6, offset:{ xxs:3 } }]}
                disableGutters
              >
                <Container>
                  <DeviceSelect onSelect={d => setActiveDevice(d)}/>
                </Container>
              </Grid>
              )
            }
          </div>
    )
  );
}

export default React.memo(Playground);
