import React, {useEffect, useLayoutEffect, useState, useRef} from 'react';
import {
  Box,
  SpaceBetween,
  Container,
  Button,
  Badge
} from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import Message from "./message";
import Panel from "./panel";
import Metadata from "./metadata";
import {logToConsole} from "../../Util";
import {feature} from "./export";

function Chat({ device }) {
  const scroll = useRef();
  const [userScroll, setUserScroll] = useState(false);
  const [history, setHistory] = React.useState([]);
  const [selected, setSelected]= useState(null);

  useEffect(() => {
    if (device) {
      const unsub = device.history().subscribe(stateChange);
      return () => unsub();
    }
  }, [device]);

  useLayoutEffect(() => {
    if (scroll && scroll.current) {
      const ref = scroll.current;
      const handleScroll = () => {
        // Debug this
        setUserScroll(ref.scrollTop < ref.scrollHeight - ref.clientHeight);
      };
      ref.addEventListener('scroll', handleScroll);
      return () => {
        ref.removeEventListener('scroll', handleScroll);
      };
    }
  });

  useLayoutEffect(() => {
    if (!userScroll && scroll.current) {
      scroll.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }, [userScroll, history]);

  const stateChange = (h) => {
    setHistory([...h.history()]);
  }

  const handleExport = () => {
    try {
      const featureItems = device.history().featureItems();
      if (featureItems.length > 0) {
        const featureContent = feature(featureItems);
        const blob = new Blob([featureContent], {type: 'text/plain'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'exported.feature';
        a.click();
        URL.revokeObjectURL(url);
      }
    } catch (err) {
      logToConsole(`Failed to export feature file ${err}`)
    }
  };

  return (
      <Container
          fitHeight
          disableHeaderPaddings
          disableContentPaddings
          header={
            <Box textAlign="center" variant="h2" padding="xs" margin="xxxs">
              Chat <Badge> {device.dsn} </Badge>
            </Box>
          }
      >
        <SpaceBetween direction="vertical">
          <div id="chatcontent" style={{
            height: '53vh',
            overflowY: 'auto',
            padding: '10px'
          }}>
            { selected ? (
                <SpaceBetween direction="vertical" size="m">
                  <Box float="right"><Button iconName="close" iconAlign="right" onClick={() => setSelected(null)}/></Box>
                  <Metadata item={selected} device={device}/>
                </SpaceBetween>
            ) : (
                <SpaceBetween direction="vertical" size="m">
                  {history.map((item) => (
                    <Message
                      key={item.id}
                      item={item}
                      onSelect={(item) => setSelected(item)}
                    />
                  ))}
                  <span ref={scroll} />
                </SpaceBetween>

            )}
          </div>
          <Box padding={{ top: "xs" }} textAlign="right">
            <Button className="awsui-util-p-n" iconName="download" variant="inline-icon" onClick={handleExport}/>
          </Box>
          <Panel device={device}/>
        </SpaceBetween>
      </Container>
  );
}

export default React.memo(Chat);
