import React, {useEffect, useState} from 'react';
import {
  Header,
  Select,
  ContentLayout,
  Button,
  FormField,
  SpaceBetween,
  Box,
  HelpPanel,
  Tiles,
  Input,
  ColumnLayout
} from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import { HexClient as client } from './client';
import { Device } from "./device";
import _ from 'lodash';

function DeviceSelect({ onSelect }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [account, setAccount] = useState({});
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [endpoint, setEndpoint] = React.useState(null);

  useEffect(() => loadDevices(), []);

  const loadDevices = async () => {
    setSelectedOption(null);
    setLoading(true);
    try {
      let resp = await client.deviceaccounts();
      let customerId = _.get(resp, "data.CustomerId", '');
      let devicesReg = _.get(resp, "data.RegisteredDevices", []);
      if (customerId && devicesReg) {
        let devices = [];
        devicesReg.map(item => {
          let device = new Device(customerId, item);
          devices.push(device);
        });
        setAccount({ customerId, devices });
      } else {
        throw Error('Device accounts info unavailable');
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  const build = (predicate, disabled) => {
    return _.map(_.filter(account.devices, predicate), device => {
      return {
        device: device,
        label: device.dsn,
        description: device.endpoint,
        tags: [device.type, device.name],
        disabled: disabled
      }
    })
  }

  const switchDevice = async () => {
    let url;
    try {
      url = _.trim(new URL(endpoint).toString(), '/');
    } catch (_) {
      window.alert("Not a valid URL. Missing http:// or https:// ?");
      return;
    }
    setDisabled(true);
    let switched = await selectedOption.device.switch(url);
    loadDevices();
    window.alert("Switched endpoint : " + switched ? "success" : "failed");
    setDisabled(false);
  }

  const options = loading ? [] : [
    {
      label: "Online",
      options: build(device => device.endpoint !== null, false)
    },
    {
      label: "Offline",
      options: build(device => device.endpoint === null, false)
    }
  ]

  return (
    <ContentLayout
      defaultPadding
      disableOverlap
      headerVariant="divider"
      header={
        <Header
          variant="h1"
          description="Switch endpoint of Alexa devices and use device Playground with OAK endpoints"
        >
          Devices
        </Header>
      }
    >
      <FormField
          secondaryControl={<Button iconName="refresh" onClick={loadDevices}/>}
      >
        <Select
            disabled={disabled}
            selectedOption={selectedOption}
            onChange={({detail}) => {
              setSelectedOption(detail.selectedOption);
            }}
            options={options}
            filteringType="auto"
            loadingText="Listing devices"
            placeholder="Choose a device"
            statusType={ loading ? "loading" : "finished" }
        />
      </FormField>
      { selectedOption && selectedOption.device && (
        <SpaceBetween size="xxs">
          <HelpPanel header={<Box textAlign="center" variant="h3"> Device Info </Box>}>
            <ColumnLayout columns={2} variant="text-grid">
              <SpaceBetween>
                <Box variant="awsui-key-label">Name</Box>
                <Box variant="p">{selectedOption.device.name}</Box>
              </SpaceBetween>
              <SpaceBetween>
                <Box variant="awsui-key-label">DSN</Box>
                <Box variant="p">{selectedOption.device.dsn}</Box>
              </SpaceBetween>
              <SpaceBetween>
                <Box variant="awsui-key-label">Type</Box>
                <Box variant="p">{selectedOption.device.type}</Box>
              </SpaceBetween>
              <SpaceBetween>
                <Box variant="awsui-key-label">Endpoint</Box>
                <Box variant="p">{selectedOption.device.endpoint}</Box>
              </SpaceBetween>
            </ColumnLayout>
          </HelpPanel>
          <HelpPanel header={<Box textAlign="center" variant="h3"> Switch Endpoint </Box>}>
            <SpaceBetween size="m">
              <FormField stretch>
                <Tiles
                    onChange={({ detail }) => setEndpoint(detail.value)}
                    value={endpoint}
                    items={[
                      {
                        value: "https://aqt-gateway.iad.amazon-aqt.com",
                        label: "OAK Prod",
                        disabled: disabled
                      },
                      {
                        value: "https://aqt-gateway.gamma.iad.amazon-aqt.com",
                        label: "OAK Gamma",
                        disabled: disabled
                      },
                      {
                        value: "https://bob-dispatch-prod-na.amazon.com",
                        label: "Alexa Prod",
                        disabled: disabled
                      },
                      {
                        value: "https://bob-dispatch-gamma-na.amazon.com",
                        label: "Alexa Gamma",
                        disabled: disabled
                      }
                    ]}
                />
              </FormField>
              <FormField label="Select or Enter Endpoint"
                         secondaryControl={<Button
                             loading={disabled}
                             disabled={!endpoint || !endpoint.length > 0}
                             onClick={switchDevice}>Switch Device</Button>}>
                <Input
                    disabled={disabled}
                    onChange={({ detail }) => setEndpoint(detail.value)}
                    value={endpoint}
                    autoComplete={false}
                    disableBrowserAutocorrect
                    inputMode="url"
                    type="url"
                />
              </FormField>
            </SpaceBetween>
          </HelpPanel>
          <FormField stretch>
            <Box textAlign={"center"}>
              <Button
                disabled={disabled || !selectedOption.device.inOak()}
                onClick={() => onSelect(selectedOption.device)}
                variant="primary">Playground</Button>
            </Box>
            <Box textAlign={"center"} color="text-status-info">Playground is available for any device connected to one of OAK endpoints.</Box>
          </FormField>
        </SpaceBetween>
      )
      }
    </ContentLayout>
  )
}

export default React.memo(DeviceSelect);
