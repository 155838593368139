import React from 'react'
import { Provider } from 'react-redux';
import store from './stores/store';
import TestList  from './pages/newRun/testList';
import CreateTest  from './pages/newRun/createTest';
// import localeStrings from './assets/translations/en.json';
import {IntlProvider} from "react-intl";
import Results from '@amzn/open-automation-kit-ui/dist/oakRun';
import { aqtStore } from 'Components/State/Store';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AppWrapper from './app';

const App = () => {
  return (
    <Results
        jwtToken={aqtStore.getState().session.idToken.jwtToken}
        environment={aqtStore.getState().environment}
        supportedScriptTypes={["csv", "feature_file", "gen_ai", "assisted"]}
      />
  )
}

export default App;
